import { Card } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import variables from '../../../utils/variables';
import { fetchCardsInfo } from '../../../actions/dashboard';
import DotsLoading from '../../../components/DotsLoading';
import exnt1 from '../../../assets/images/exnt-1.png';
import exnt2 from '../../../assets/images/exnt-2.png';
import './index.css';

class Cards extends Component {
    componentDidMount () {
        if (this.props.inProgress || Object.keys(this.props.data).length > 0) {
            return;
        }

        this.props.fetch();
    }

    componentDidUpdate (pp, ps, ss) {
        if (this.props.auth && (pp.auth !== this.props.auth)) {
            this.props.fetch();
        }
    }

    render () {
        return (
            <div className="cards -mx-4 flex-wrap justify-center">
                <Card className="w-full lg:w-1/3 special-card mb-6 lg:mb-6">
                    <div className="bg-custom-dark mx-4 rounded-lg p-4 relative h-full">
                        <span className="mb-10 block text-base font-oswald-text font-semibold text-white opacity-80">
                            {this.props.inProgress
                                ? <DotsLoading/>
                                : this.props.data && (this.props.data.unstake_requests_count ||
                                    this.props.data.unstake_requests_count === 0)
                                    ? this.props.data.unstake_requests_count + ' '
                                    : null}
                            {variables[this.props.lang]['pending_exnt_stake']}
                        </span>
                        <div className="z-10 relative">
                            {this.props.inProgress
                                ? <DotsLoading/>
                                : this.props.data && (this.props.data.unstake_requests ||
                                    this.props.data.unstake_requests === 0)
                                    ? <span
                                        className="font-extrabold font-oswald-text text-white text-2xl value"
                                        title={this.props.data.unstake_requests * Math.pow(10, -16)}>
                                        {this.props.data.unstake_requests * Math.pow(10, -16)}
                                    </span>
                                    : <span className="font-extrabold font-oswald-text text-2xl value">
                                        NA
                                    </span>}
                        </div>
                        <div className="absolute bottom-0 right-0 w-5/12 opacity-40">
                            <img alt="exnt_1" className="w-full" src={exnt1}/>
                        </div>
                    </div>
                </Card>
                <Card className="w-full lg:w-1/3 special-card mb-6 lg:mb-6">
                    <div className="bg-custom-dark mx-4 rounded-lg p-4 relative h-full">
                        <span className="mb-10 block text-base font-oswald-text font-semibold text-white opacity-80">
                            {this.props.inProgress
                                ? <DotsLoading/>
                                : this.props.data && (this.props.data.claim_requests_count ||
                                    this.props.data.claim_requests_count === 0)
                                    ? this.props.data.claim_requests_count + ' '
                                    : null}
                            {variables[this.props.lang]['pending_exnt_claim']}
                        </span>
                        <div className="z-10 relative">
                            {this.props.inProgress
                                ? <DotsLoading/>
                                : this.props.data && (this.props.data.claim_requests_sum ||
                                    this.props.data.claim_requests_sum === 0)
                                    ? <span
                                        className="font-extrabold font-oswald-text text-white text-2xl block break-all value"
                                        title={this.props.data.claim_requests_sum * Math.pow(10, -16)}>
                                        {this.props.data.claim_requests_sum * Math.pow(10, -18)}
                                    </span>
                                    : <span className="font-extrabold font-oswald-text text-white text-2xl block break-all value">
                                    NA
                                    </span>}
                        </div>
                        <div className="absolute bottom-0 right-0 w-5/12 opacity-40">
                            <img alt="exnt_2" className="w-full" src={exnt2}/>
                        </div>
                    </div>
                </Card>
                <Card className="w-full lg:w-1/3 special-card mb-6 lg:mb-6">
                    <div className="bg-custom-dark mx-4 rounded-lg p-4 relative h-full">
                        <span className="mb-10 block text-base font-oswald-text font-semibold text-white opacity-80">
                            {this.props.inProgress
                                ? <DotsLoading/>
                                : this.props.data && (this.props.data.staked_count ||
                                    this.props.data.staked_count === 0)
                                    ? this.props.data.staked_count + ' '
                                    : null}
                            {variables[this.props.lang]['staked_exnt']}
                        </span>
                        <div className="z-10 relative">
                            {this.props.inProgress
                                ? <DotsLoading/>
                                : this.props.data && (this.props.data.staked ||
                                    this.props.data.staked === 0)
                                    ? <span
                                        className="font-extrabold font-oswald-text text-white text-2xl break-all value"
                                        title={this.props.data.staked * Math.pow(10, -16)}>
                                        {this.props.data.staked * Math.pow(10, -16)}
                                    </span>
                                    : <span className="font-extrabold font-oswlad-text text-white text-2xl value">
                                        NA
                                    </span>}
                        </div>
                        <div className="absolute bottom-0 right-0 w-5/12 opacity-40">
                            <img alt="exnt_1" className="w-full" src={exnt1}/>
                        </div>
                    </div>
                </Card>
                <Card className="w-full lg:w-1/3 special-card mb-6 lg:mb-6">
                    <div className="bg-custom-dark mx-4 rounded-lg p-4 relative h-full">
                        <span className="mb-10 block text-base font-oswald-text font-semibold text-white opacity-80">
                            {this.props.inProgress
                                ? <DotsLoading/>
                                : this.props.data && (this.props.data.unstaked_count ||
                                    this.props.data.unstaked_count === 0)
                                    ? this.props.data.unstaked_count + ' '
                                    : null}
                            {variables[this.props.lang]['un_staked_exnt']}
                        </span>
                        <div className="z-10 relative">
                            {this.props.inProgress
                                ? <DotsLoading/>
                                : this.props.data && (this.props.data.unstaked ||
                                    this.props.data.unstaked === 0)
                                    ? <span
                                        className="font-extrabold font-oswald-text text-white text-2xl break-all value"
                                        title={this.props.data.unstaked * Math.pow(10, -16)}>
                                        {this.props.data.unstaked * Math.pow(10, -16)}
                                    </span>
                                    : <span className="font-extrabold font-oswald-text text-white text-2xl value">
                                        NA
                                    </span>}
                        </div>
                        <div className="absolute bottom-0 right-0 w-5/12 opacity-40">
                            <img alt="exnt_2" className="w-full" src={exnt2}/>
                        </div>
                    </div>
                </Card>
                <Card className="w-full lg:w-1/3 special-card mb-6 lg:mb-6">
                    <div className="bg-custom-dark mx-4 rounded-lg p-4 relative h-full">
                        <p className="mb-10 block text-base font-oswald-text font-semibold text-white opacity-80">{variables[this.props.lang]['circulating_exnt']}</p>
                        <div className="z-10 relative">
                            {this.props.inProgress
                                ? <DotsLoading/>
                                : this.props.data && this.props.data
                                    ? <span className="font-extrabold font-oswald-text text-white text-2xl value">
                                        NA
                                    </span>
                                    : <span className="font-extrabold font-oswald-text text-white text-2xl value">
                                        NA
                                    </span>}
                        </div>
                        <div className="absolute bottom-0 right-0 w-5/12 opacity-40">
                            <img alt="exnt_1" className="w-full" src={exnt1}/>
                        </div>
                    </div>
                </Card>
            </div>
        );
    }
}

Cards.propTypes = {
    data: PropTypes.object.isRequired,
    fetch: PropTypes.func.isRequired,
    inProgress: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    auth: PropTypes.string,
};

const stateToProps = (state) => {
    return {
        auth: state.accessToken.value,
        data: state.dashboard.cards.value,
        inProgress: state.dashboard.cards.inProgress,
        lang: state.language,
    };
};

const actionToProps = {
    fetch: fetchCardsInfo,
};

export default connect(stateToProps, actionToProps)(Cards);
