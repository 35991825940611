import React, { Component } from 'react';
import './index.css';
import { withRouter } from 'react-router';
import PasswordTextField from './PasswordTextField';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import variables from '../../utils/variables';
import EmailTextField from './EmailTextField';
import RememberButton from './RememberButton';
import SignInButton from './SignInButton';
import { setEmailId, setPassword, setRememberPassword, userSignIn } from '../../actions/signIn';
import { decryptToken, encryptToken } from '../../utils/crypto';
import FormInfo from '../../components/FormInfo';

class SignIn extends Component {
    constructor (props) {
        super(props);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handleSignIn = this.handleSignIn.bind(this);
        this.handleClear = this.handleClear.bind(this);
    }

    componentDidMount () {
        const email = localStorage.getItem('email');
        const password = localStorage.getItem('password');

        if (email && password) {
            this.props.setEmailId(email);
            this.props.setPassword(decryptToken(password));
            this.props.setRememberPassword(true);
        }
    }

    handleKeyPress (event) {
        const disable = this.props.email === '' || this.props.password === '' || this.props.inProgress;

        if (event.key === 'Enter' && !disable) {
            this.handleSignIn();
        }
    }

    handleSignIn () {
        const data = {
            emailAddress: this.props.email,
            password: encryptToken(this.props.password),
        };

        this.props.userSignIn(data, (error) => {
            if (!error && this.props.rememberPassword) {
                localStorage.setItem('email', this.props.email);
                localStorage.setItem('password', encryptToken(this.props.password));

                this.props.twoFactorAuthStatus === 'ENABLED'
                    ? this.props.history.push('/verify-auth')
                    : this.props.history.push('/dashboard');
                this.handleClear();
            } else if (!error && !this.props.rememberPassword) {
                if (localStorage.getItem('email')) {
                    localStorage.removeItem('email');
                }
                if (localStorage.getItem('password')) {
                    localStorage.removeItem('password');
                }
                this.props.twoFactorAuthStatus === 'ENABLED'
                    ? this.props.history.push('/verify-auth')
                    : this.props.history.push('/dashboard');
                this.handleClear();
            } else if (error && error.response && error.response.status === 401) {
                this.props.history.push('/verify-email');
            }
        });
    }

    handleClear () {
        if (this.props.email) {
            this.props.setEmailId('');
        }
        if (this.props.password) {
            this.props.setPassword('');
        }
    }

    render () {
        const disable = this.props.email === '' || this.props.password === '' || this.props.inProgress;

        return (
            <div className="w-full flex items-center lg:items-start bg-custom-dark">
                <div className=" flex flex-col-reverse lg:flex-row-reverse bg-custom-dark h-screen lg:h-auto items-center w-full">
                    <div className="flex items-center justify-center lg:w-1/2 w-full bg-custom-dark md:mb-10 md:mb-auto h-screen items-center md:items">
                        <form
                            noValidate
                            autoComplete="off"
                            className="card w-4/5 md:w-3/5"
                            onKeyPress={this.handleKeyPress}>
                            <h3 className="heading font-oswald-text font-extrabold text-3xl mb-3">{variables[this.props.lang]['sign_in']}</h3>
                            <p className="text-custom-gray text-base mb-5 font-semibold opacity-60 mb-12 block">{variables[this.props.lang]['sign_in_text']}</p>
                            <EmailTextField/>
                            <PasswordTextField/>
                            <div className="flex justify-between items-center mb-10">
                                <RememberButton/>
                                <a
                                    className="text-sm font-nunito-text text-custom-light-blue font-semibold"
                                    href="/forgot-password"
                                    onClick={this.handleClear}>
                                    {variables[this.props.lang]['forgot_password']}?
                                </a>
                            </div>
                            <SignInButton
                                disable={disable}
                                lang={this.props.lang}
                                onClick={this.handleSignIn}/>
                            <div
                                className="flex_row text-center mt-12 text-base font-oswald-text font-semibold">
                                <p className="opacity-70">
                                    {variables[this.props.lang]['no_account']}
                                    &nbsp;<a
                                        className="text-custom-light-blue"
                                        href="/register"
                                        onClick={this.handleClear}>
                                        {variables[this.props.lang]['sign_up']}
                                    </a>
                                </p>
                            </div>
                            {/* <div className="flex_row text-center mt-2 md:mt-5 text-sm font-nunito-text  text-custom-light-gray font-semibold">
                                <a
                                    className="text-custom-light-blue"
                                    href="/verify-email"
                                    onClick={this.handleClear}>
                                    {variables[this.props.lang]['verify_your_email']}
                                </a>
                            </div> */}
                        </form>
                    </div>
                    <FormInfo/>
                </div>
            </div>
        );
    }
}

SignIn.propTypes = {
    email: PropTypes.string.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    inProgress: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    rememberPassword: PropTypes.bool.isRequired,
    setEmailId: PropTypes.func.isRequired,
    setPassword: PropTypes.func.isRequired,
    setRememberPassword: PropTypes.func.isRequired,
    twoFactorAuthStatus: PropTypes.string.isRequired,
    userSignIn: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
    return {
        email: state.signIn.emailId,
        inProgress: state.signIn.inProgress,
        lang: state.language,
        password: state.signIn.password,
        rememberPassword: state.signIn.rememberPassword,
        twoFactorAuthStatus: state.settings.twoFactorAuthStatus,
    };
};

const actionToProps = {
    setEmailId,
    setPassword,
    setRememberPassword,
    userSignIn,
};

export default withRouter(connect(stateToProps, actionToProps)(SignIn));
