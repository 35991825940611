export const ACTIVE_FILTER_SET = 'ACTIVE_FILTER_SET';
export const SIGNUPS_FILTER_SET = 'SIGNUPS_FILTER_SET';

export const USERS_LIST_FETCH_IN_PROGRESS = 'USERS_LIST_FETCH_IN_PROGRESS';
export const USERS_LIST_FETCH_SUCCESS = 'USERS_LIST_FETCH_SUCCESS';
export const USERS_LIST_FETCH_ERROR = 'USERS_LIST_FETCH_ERROR';

export const SPECIFIC_USER_INFO_ERROR = 'SPECIFIC_USER_INFO_ERROR';
export const SPECIFIC_USER_INFO_IN_PROGRESS = 'SPECIFIC_USER_INFO_IN_PROGRESS';
export const SPECIFIC_USER_INFO_SUCCESS = 'SPECIFIC_USER_INFO_SUCCESS';

export const SPECIFIC_PROFILE_IMAGE_FETCH_IN_PROGRESS = 'SPECIFIC_PROFILE_IMAGE_FETCH_IN_PROGRESS';
export const SPECIFIC_PROFILE_IMAGE_FETCH_SUCCESS = 'SPECIFIC_PROFILE_IMAGE_FETCH_SUCCESS';
export const SPECIFIC_PROFILE_IMAGE_FETCH_ERROR = 'SPECIFIC_PROFILE_IMAGE_FETCH_ERROR';

export const SPECIFIC_BANNER_IMAGE_FETCH_IN_PROGRESS = 'SPECIFIC_BANNER_IMAGE_FETCH_IN_PROGRESS';
export const SPECIFIC_BANNER_IMAGE_FETCH_SUCCESS = 'SPECIFIC_BANNER_IMAGE_FETCH_SUCCESS';
export const SPECIFIC_BANNER_IMAGE_FETCH_ERROR = 'SPECIFIC_BANNER_IMAGE_FETCH_ERROR';
