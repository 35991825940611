import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import CloseDealModal from './CloseDealModal';
import CircularProgress from '../../components/CircularProgress';
import Snackbar from '../../components/Snackbar';
import DataTable from '../../components/DataTable';
import CopyButton from '../../components/CopyButton';
import DealModal from '../Deals/DealModal';
import {
    fetchDeal,
    fetchContributors,
} from '../../actions/deals';
import { fetchDistributionDetails } from '../../actions/distribution';
import variables from '../../utils/variables';
import {
    DEFAULT_LIMIT,
    DEFAULT_ORDER,
    DEFAULT_SEARCH_KEY,
    DEFAULT_SKIP,
    DEFAULT_SORT_BY,
} from '../../constants/url';
import Icon from '../../components/Icon';
import { contributorsToCSV } from '../../utils/common';
import ArchiveDealModal from './ArchiveDealModal';
import DistributionModal from './DistributionModal';
import Button from '../../components/Button';
import './index.css';

class DealDetails extends Component {
    constructor (props) {
        super(props);

        this.state = {
            openModal: false,
            openArchiveDealModal: false,
            openCloseDealModal: false,
            snackbarMessage: '',
            snackbarOpen: false,
            snackbarSeverity: 'success',
            openDistributionModal: false,
            openUpdateDistributionModal: false,
            test: null,
        };

        this.goBack = this.goBack.bind(this);
        this.openUpdateModal = this.openUpdateModal.bind(this);
        this.openDistributionModal = this.openDistributionModal.bind(this);
        this.onCloseDistributionModal = this.onCloseDistributionModal.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
        this.handleFetchDeal = this.handleFetchDeal.bind(this);
        this.handleFetchContributors = this.handleFetchContributors.bind(this);
        this.onSuccess = this.onSuccess.bind(this);
        this.handleSnackbarClose = this.handleSnackbarClose.bind(this);
        this.onCloseDealModalClose = this.onCloseDealModalClose.bind(this);
        this.onCloseDealModalOpen = this.onCloseDealModalOpen.bind(this);
        this.onArchiveDealModalOpen = this.onArchiveDealModalOpen.bind(this);
        this.onArchiveDealModalClose = this.onArchiveDealModalClose.bind(this);
    }

    componentDidMount () {
        this.handleFetchDeal();
        this.handleFetchContributors(
            DEFAULT_SKIP,
            DEFAULT_LIMIT,
            DEFAULT_SEARCH_KEY,
            DEFAULT_SORT_BY,
            DEFAULT_ORDER,
        );
    }

    handleFetchDeal () {
        const {
            fetch,
            fetchDistributionDetails,
            match: {
                params: {
                    id,
                },
            },
        } = this.props;
        fetch(id);
        fetchDistributionDetails(id);
    }

    handleFetchContributors (skip, limit, searchText, sortBy, order) {
        const {
            fetchContributors,
            match: {
                params: {
                    id,
                },
            },
        } = this.props;
        fetchContributors(id, skip, limit, searchText, sortBy, order);
    }

    handleViewDetails (value) {
        const {
            match: {
                params: {
                    id,
                },
            },
            history,
        } = this.props;

        history.push(`/deals/${id}/contributors/${value}`);
    }

    goBack () {
        const { history } = this.props;

        history.goBack();
    }

    openUpdateModal () {
        this.setState({
            openModal: true,
        });
    }

    onCloseModal () {
        this.setState({
            openModal: false,
        });
    }

    openDistributionModal () {
        this.setState({
            openDistributionModal: true,
        });
    }

    onCloseDistributionModal () {
        this.setState({
            openDistributionModal: false,
        });
    }

    onArchiveDealModalClose () {
        this.setState({
            openArchiveDealModal: false,
        });
    }

    onArchiveDealModalOpen () {
        const { contributors } = this.props;

        if (contributors && contributors.length > 0) {
            this.setState({
                snackbarMessage: 'You cannot archive an active deal with contributors.',
                snackbarOpen: true,
                snackbarSeverity: 'warning',
            });
        } else {
            this.setState({
                openArchiveDealModal: true,
            });
        }
    }

    onCloseDealModalClose () {
        this.setState({
            openCloseDealModal: false,
        });
    }

    onCloseDealModalOpen () {
        this.setState({
            openCloseDealModal: true,
        });
    }

    onSuccess (message) {
        this.setState({
            snackbarMessage: message,
            snackbarOpen: true,
            snackbarSeverity: 'success',
        });
    }

    handleSnackbarClose () {
        this.setState({ snackbarOpen: false });
    }

    render () {
        const {
            inProgress,
            data,
            contributors,
            lang,
            count,
            match: {
                params: {
                    id,
                },
            },
            distributionDetails,
        } = this.props;

        const {
            snackbarMessage,
            snackbarOpen,
            snackbarSeverity,
            openArchiveDealModal,
            openCloseDealModal,
            openModal,
            openDistributionModal,
        } = this.state;

        const { _id } = data;

        const contributorOptions = {
            print: false,
            count,
            selectableRows: 'none',
            responsive: 'vertical',
            serverSide: true,
            onDownload: (buildHead, buildBody, columns, data) => {
                contributorsToCSV(id);
                return false;
            },
            onSearchChange: (searchText) => {
                const { skip, limit, sortBy } = this.props;
                this.handleFetchContributors(skip, limit, searchText, sortBy, null);
            },
            onChangePage: (currentPage) => {
                const { limit, sortBy, contributors, searchKey } = this.props;

                if (contributors && contributors.length === 0) {
                    return;
                }

                this.handleFetchContributors(limit * currentPage, limit, searchKey, sortBy, null);
            },
            onChangeRowsPerPage: (numberOfRows) => {
                const { skip, sortBy, contributors, searchKey } = this.props;

                if (contributors && contributors.length === 0) {
                    return;
                }

                this.handleFetchContributors(skip, numberOfRows, searchKey, sortBy, null);
            },
            onColumnSortChange: (changedColumn, direction) => {
                const { skip, limit, contributors, searchKey } = this.props;

                if (contributors && contributors.length === 0) {
                    return;
                }

                const order = direction === 'asc' ? 'asc' : 'desc';
                this.handleFetchContributors(skip, limit, searchKey, changedColumn, order);
            },
        };

        const contributorColumns = [
            {
                name: 'tier_group',
                label: 'Tier Group',
                options: {
                    filter: false,
                    sort: false,
                },
            },
            {
                name: 'tg_username',
                label: 'TG Username',
                options: {
                    filter: false,
                    sort: true,
                    customBodyRender: (value) => (
                        <Link className="font-bold deal-detail__link" to={`/user/${value.user._id}`}>{value.user.telegram.username}</Link>
                    ),
                },
            },
            {
                name: 'email_address',
                label: 'Email Address',
                options: {
                    filter: false,
                    sort: true,
                    customBodyRender: (value) => (
                        <Link className="font-bold deal-detail__link" to={`/user/${value.user._id}`}>{value.user.email_address}</Link>
                    ),
                },
            },
            {
                name: 'contribution',
                label: 'Contribution',
                options: {
                    filter: false,
                    sort: true,
                },
            },
            {
                name: 'action',
                label: variables[lang].actions,
                options: {
                    filter: false,
                    sort: false,
                    // eslint-disable-next-line react/display-name
                    customBodyRender: (value) => (
                        <Button
                            className="view_details_button"
                            onClick={() => this.handleViewDetails(value)}
                        >
                            {variables[lang]['view_details']}
                        </Button>
                    ),
                },
            },
        ];

        const contributorTableData = contributors && contributors.length
            ? contributors.map((item) =>
                [
                    item.user.top_tier ? item.user.top_tier : '',
                    item,
                    item,
                    item.contribution && item.contribution.toLocaleString('en-US'),
                    item._id,
                ])
            : [];

        return (
            <div className="deal_details content_padding">
                <Button className="flex items-center" onClick={this.goBack}>
                    <Icon
                        className="dark-back-arrow mr-2"
                        icon="dark-back-arrow"/>
                    <span className="font-oswald-text font-bold text-white">Back</span>
                </Button>
                {inProgress
                    ? <CircularProgress />
                    : <div className="mt-6">
                        <div className="bg-custom-dark rounded-lg shadow-md">
                            <div className="deal_details__header w-full mx-auto py-4 md:py-8 px-4 md:px-10 border-b">
                                <h1 className="font-oswald-text font-bold text-2xl md:text-3xl">Deals Details</h1>
                            </div>
                            <div className="w-full pt-6 md:pt-14 pb-8 px-4 md:px-10 mb-8 text-custom-white">
                                <div className="flex mb-8 items-top md:items-center">
                                    <p className="font-oswald-text text-sm md:text-base font-bold w-full md:w-1/6 text-custom-white">{variables[lang]['new_deal_form_name']}</p>
                                    <p className="font-oswald-text text-sm md:text-base font-bold w-full md:w-5/6 text-custom-white">
                                        {data.full_name && <Tooltip placement="right" title={data.full_name}>
                                            <span>{data.full_name}</span>
                                        </Tooltip>}
                                    </p>
                                </div>
                                <div className="md:flex mb-8 items-top md:items-center">
                                    <p className="font-oswald-text text-sm md:text-base font-bold w-full md:w-1/6 text-custom-white">{variables[lang]['new_deal_form_description']}</p>
                                    <xmp className="font-oswald-text text-sm md:text-base font-bold w-full md:w-5/6">{data.description}</xmp>
                                </div>
                                <div className="md:flex mb-8 items-top md:items-center">
                                    <p className="font-oswald-text text-sm md:text-base font-bold w-full md:w-1/6 text-custom-white">{variables[lang]['new_deal_form_fee']}</p>
                                    <p className="font-oswald-text text-sm md:text-base font-bold w-full md:w-5/6">{data.fee}</p>
                                </div>
                                <div className="md:flex mb-8 items-top md:items-center">
                                    <p className="font-oswald-text text-sm md:text-base font-bold w-full md:w-1/6 text-custom-white">{variables[lang]['new_deal_form_round']}</p>
                                    <p className="font-oswald-text text-sm md:text-base font-bold w-full md:w-5/6">{data.round}</p>
                                </div>
                                {data.total_allocation &&
                                    <div className="md:flex mb-8 items-top md:items-center">
                                        <p className="font-oswald-text text-sm md:text-base font-bold w-full md:w-1/6 text-custom-white">{variables[lang]['new_deal_form_allocation']}</p>
                                        <p className="font-oswald-text text-sm md:text-base font-bold w-full md:w-5/6">{data.total_allocation && data.total_allocation.toLocaleString('en-us')}</p>
                                    </div>
                                }
                                {data.owner &&
                                    <div className="flex flex-col md:flex-row mb-8 items-top md:items-center">
                                        <p className="font-oswald-text text-sm md:text-base font-bold w-full md:w-1/6 text-custom-white">{variables[lang]['new_deal_form_owner']}</p>
                                        <p className="font-oswald-text text-sm md:text-base font-bold w-full md:w-5/6  break-all">{data.owner.email_address}</p>
                                    </div>
                                }
                                <div className="md:flex mb-8 items-top md:items-center">
                                    <p className="font-oswald-text text-sm md:text-base font-bold w-full md:w-1/6 text-custom-white">{variables[lang]['new_deal_form_gather']}</p>
                                    <div className="icon_div font-oswald-text text-sm md:text-base font-bold w-full md:w-5/6 items-center">
                                        <a
                                            className="id_text font-oswald-text text-sm md:text-base break-all"
                                            href={data.gather_link}
                                            rel="noopener noreferrer"
                                            target="_blank"
                                            title={data.gather_link}>
                                            {data.gather_link}
                                        </a>
                                        <CopyButton data={data.gather_link}/>
                                    </div>
                                </div>
                                {data.link_1 &&
                                    <div className="md:flex mb-8 items-top md:items-center">
                                        <p className="font-oswald-text text-sm md:text-base font-bold w-full md:w-1/6 text-custom-white">{variables[lang]['new_deal_form_link1']}</p>
                                        <div className="icon_div font-oswald-text text-sm md:text-base font-bold w-full md:w-5/6 items-center">
                                            <a
                                                className="id_text font-oswald-text text-sm md:text-base break-all"
                                                href={data.link_1}
                                                rel="noopener noreferrer"
                                                target="_blank"
                                                title={data.link_1}>
                                                {data.link_1}
                                            </a>
                                            <CopyButton data={data.link_1}/>
                                        </div>
                                    </div>
                                }
                                {data.link_2 &&
                                    <div className="md:flex mb-8 items-top md:items-center">
                                        <p className="font-oswald-text text-sm md:text-base font-bold w-full md:w-1/6 text-custom-white">{variables[lang]['new_deal_form_link2']}</p>
                                        <div className="icon_div font-oswald-text text-sm md:text-base font-bold w-full md:w-5/6 items-center">
                                            <a
                                                className="id_text font-oswald-text text-sm md:text-base break-all"
                                                href={data.link_2}
                                                rel="noopener noreferrer"
                                                target="_blank"
                                                title={data.link_2}>
                                                {data.link_2}
                                            </a>
                                            <CopyButton data={data.link_2}/>
                                        </div>
                                    </div>
                                }
                                {data.link_3 &&
                                    <div className="md:flex mb-8 items-top md:items-center">
                                        <p className="font-oswald-text text-sm md:text-base font-bold w-full md:w-1/6 text-custom-white">{variables[lang]['new_deal_form_link3']}</p>
                                        <div className="icon_div font-oswald-text text-sm md:text-base font-bold w-full md:w-5/6 items-center">
                                            <a
                                                className="id_text font-oswald-text text-sm md:text-base break-all"
                                                href={data.link_3}
                                                rel="noopener noreferrer"
                                                target="_blank"
                                                title={data.link_3}>
                                                {data.link_3}
                                            </a>
                                            <CopyButton data={data.link_3}/>
                                        </div>
                                    </div>
                                }
                                <div className="flex mb-8 items-top md:items-center">
                                    <p className="font-oswald-text text-sm md:text-base font-bold w-full md:w-1/6 text-custom-white">{variables[lang]['new_deal_form_status']}</p>
                                    <p className="font-oswald-text text-sm md:text-base font-bold w-full md:w-5/6 text-custom-white">{data.status}</p>
                                </div>
                                {
                                    !isEmpty(distributionDetails) && <>
                                        <div className="flex mb-8 items-top md:items-center">
                                            <p className="font-oswald-text text-sm md:text-base font-bold w-full md:w-1/6 text-custom-white">{variables[lang]['vesting_details']}</p>
                                            <xmp className="font-oswald-text text-sm md:text-base font-bold w-full md:w-5/6">{distributionDetails.vesting_details}</xmp>
                                        </div>
                                    </>
                                }
                                {
                                    (data.is_archive === true) && (
                                        <>
                                            <p className="font-oswald-text text-sm md:text-base font-bold w-full md:w-1/6 text-custom-white">&nbsp;</p>
                                            <p className="font-oswald-text text-sm md:text-base font-bold w-full md:w-5/6 text-custom-white archived-text">This deal is archived.</p>
                                        </>
                                    )
                                }
                                <div className="text-center md:text-right">
                                    { (data.is_archive !== true) ? (
                                        (data.status === 'ACTIVE') ? (
                                            <>
                                                {isEmpty(distributionDetails)
                                                    ? <Button className="font-oswald-text text-sm md:text-base font-bold px-4 py-2 w-full md:w-auto" color="secondary" onClick={this.openDistributionModal}>
                                                        {variables[lang]['add_distribution_details']}
                                                    </Button>
                                                    : <Button className="font-oswald-text text-sm md:text-base font-bold px-4 py-2 w-full md:w-auto" color="secondary" onClick={this.openDistributionModal}>
                                                        {variables[lang]['update_distribution_details']}
                                                    </Button>
                                                }&nbsp;&nbsp;
                                                <Button className="font-oswald-text text-sm md:text-base font-bold px-4 py-2 w-full md:w-auto" color="secondary" onClick={this.openUpdateModal}>{variables[lang]['update_deal']}</Button>&nbsp;&nbsp;
                                                <Button className="font-oswald-text text-sm md:text-base font-bold px-4 py-2 w-full md:w-auto" color="primary" onClick={this.onArchiveDealModalOpen}>{variables[lang]['archive_deal']}</Button>&nbsp;&nbsp;
                                                <Button className="font-oswald-text text-sm md:text-base font-bold px-4 py-2 w-full md:w-auto" color="primary" onClick={this.onCloseDealModalOpen}>{variables[lang]['close_deal']}</Button>
                                            </>
                                        ) : (
                                            <Button className="font-oswald-text text-sm md:text-base font-bold reopen_deal" onClick={this.onCloseDealModalOpen}>{variables[lang]['reopen_deal']}</Button>
                                        )
                                    ) : <Button className="font-oswald-text text-sm md:text-base font-bold archive_deal" onClick={this.onArchiveDealModalOpen}>{variables[lang]['unarchive_deal']}</Button>
                                    }
                                </div>
                            </div>
                        </div>
                        <DataTable
                            className="pt-4 pb-4 mb-4"
                            columns={contributorColumns}
                            data={contributorTableData}
                            name="Contributors"
                            options={contributorOptions}
                        />
                        <Snackbar
                            message={snackbarMessage}
                            open={snackbarOpen}
                            severity={snackbarSeverity}
                            onClose={this.handleSnackbarClose}
                        />
                        {
                            !isEmpty(data) ? (
                                <>
                                    <DistributionModal
                                        contributorData={contributors}
                                        dealId={id}
                                        distributionDetails={distributionDetails}
                                        fetchDefault={this.handleFetchDeal}
                                        open={openDistributionModal}
                                        onClose={this.onCloseDistributionModal}
                                        onSuccess={this.onSuccess}
                                    />
                                    <DealModal
                                        dealData={data}
                                        fetchDefault={this.handleFetchDeal}
                                        open={openModal}
                                        onClose={this.onCloseModal}
                                        onSuccess={this.onSuccess}
                                    />
                                    <CloseDealModal
                                        fetchDefault={this.handleFetchDeal}
                                        id={_id}
                                        open={openCloseDealModal}
                                        status={data.status}
                                        onClose={this.onCloseDealModalClose}
                                        onSuccess={this.onSuccess}
                                    />
                                    <ArchiveDealModal
                                        fetchDefault={this.handleFetchDeal}
                                        id={_id}
                                        isArchived={data.is_archive}
                                        open={openArchiveDealModal}
                                        onClose={this.onArchiveDealModalClose}
                                        onSuccess={this.onSuccess}
                                    />
                                </>
                            ) : ''
                        }
                    </div>
                }
            </div>
        );
    }
}

DealDetails.propTypes = {
    contributors: PropTypes.array.isRequired,
    count: PropTypes.number.isRequired,
    data: PropTypes.object.isRequired,
    distributionDetails: PropTypes.object.isRequired,
    fetch: PropTypes.func.isRequired,
    fetchContributors: PropTypes.func.isRequired,
    fetchDistributionDetails: PropTypes.func.isRequired,
    history: PropTypes.shape({
        goBack: PropTypes.func.isRequired,
        push: PropTypes.func.isRequired,
    }).isRequired,
    inProgress: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    limit: PropTypes.number.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.shape({
        params: PropTypes.object.isRequired,
    }).isRequired,
    searchKey: PropTypes.string.isRequired,
    skip: PropTypes.number.isRequired,
    sortBy: PropTypes.string.isRequired,
};

const stateToProps = (state) => {
    return {
        inProgress: state.deals.singleDeal.inProgress,
        data: state.deals.singleDeal.result,
        contributors: state.deals.listContributors.list,
        count: state.deals.listContributors.count,
        skip: state.deals.listContributors.skip,
        limit: state.deals.listContributors.limit,
        searchKey: state.deals.listContributors.searchKey,
        sortBy: state.deals.listContributors.sortBy,
        order: state.deals.listContributors.order,
        lang: state.language,
        distributionDetails: state.distribution.distributionDetails.result,
    };
};

const actionToProps = {
    fetch: fetchDeal,
    fetchContributors,
    fetchDistributionDetails,
};

export default withRouter(connect(stateToProps, actionToProps)(DealDetails));
