import {
    ACTIVE_FILTER_SET,
    SIGNUPS_FILTER_SET,
    USERS_LIST_FETCH_ERROR,
    USERS_LIST_FETCH_IN_PROGRESS,
    USERS_LIST_FETCH_SUCCESS,
    SPECIFIC_USER_INFO_ERROR,
    SPECIFIC_USER_INFO_IN_PROGRESS,
    SPECIFIC_USER_INFO_SUCCESS,
    SPECIFIC_PROFILE_IMAGE_FETCH_IN_PROGRESS,
    SPECIFIC_PROFILE_IMAGE_FETCH_SUCCESS,
    SPECIFIC_PROFILE_IMAGE_FETCH_ERROR,
    SPECIFIC_BANNER_IMAGE_FETCH_IN_PROGRESS,
    SPECIFIC_BANNER_IMAGE_FETCH_SUCCESS,
    SPECIFIC_BANNER_IMAGE_FETCH_ERROR,
} from '../constants/users';
import { usersListURL, specificUserInfo, specificUserimage, specificUserBanner } from '../constants/url';
import Axios from 'axios';

export const setActiveFilter = (value) => {
    return {
        type: ACTIVE_FILTER_SET,
        value,
    };
};

export const setSignupsFilter = (value) => {
    return {
        type: SIGNUPS_FILTER_SET,
        value,
    };
};

const fetchUsersListInProgress = () => {
    return {
        type: USERS_LIST_FETCH_IN_PROGRESS,
    };
};

const fetchUsersListSuccess = (value, skip, limit, searchKey, sortBy, order) => {
    return {
        type: USERS_LIST_FETCH_SUCCESS,
        value,
        skip,
        limit,
        searchKey,
        sortBy,
        order,
    };
};

const fetchUsersListError = (message) => {
    return {
        type: USERS_LIST_FETCH_ERROR,
        message,
    };
};

export const fetchUsersList = (skip, limit, searchKey, sortBy, order) => (dispatch) => {
    dispatch(fetchUsersListInProgress());

    const URL = usersListURL(skip, limit, searchKey, sortBy, order);
    Axios.get(URL, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
    })
        .then((res) => {
            dispatch(fetchUsersListSuccess(res.data && res.data.result,
                skip, limit, searchKey, sortBy, order));
        })
        .catch((error) => {
            dispatch(fetchUsersListError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

const fetchSpecificUserInProgress = () => {
    return {
        type: SPECIFIC_USER_INFO_IN_PROGRESS,
    };
};

const fetchSpecificUserSuccess = (value) => {
    return {
        type: SPECIFIC_USER_INFO_SUCCESS,
        value,
    };
};

const fetchSpecificUserError = (message) => {
    return {
        type: SPECIFIC_USER_INFO_ERROR,
        message,
    };
};

export const fetchSpecificUser = (id) => (dispatch) => {
    dispatch(fetchSpecificUserInProgress());

    const URL = specificUserInfo(id);
    Axios.get(URL, {
        headers: {
            Accept: 'application/json',
        },
    })
        .then((res) => {
            dispatch(fetchSpecificUserSuccess(res.data));
        })
        .catch((error) => {
            dispatch(fetchSpecificUserError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

const fetchSpecificProfileImageInProgress = () => {
    return {
        type: SPECIFIC_PROFILE_IMAGE_FETCH_IN_PROGRESS,
    };
};

const fetchSpecificProfileImageSuccess = (value) => {
    return {
        type: SPECIFIC_PROFILE_IMAGE_FETCH_SUCCESS,
        value,
    };
};

const fetchSpecificProfileImageError = (message) => {
    return {
        type: SPECIFIC_PROFILE_IMAGE_FETCH_ERROR,
        message,
    };
};

export const fetchSpecificProfileImage = (id) => (dispatch) => {
    dispatch(fetchSpecificProfileImageInProgress());

    const URL = specificUserimage(id);
    Axios.get(URL, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
    })
        .then((res) => {
            const imageUrl = { url: res.data.result };
            dispatch(fetchSpecificProfileImageSuccess(imageUrl));
        })
        .catch((error) => {
            dispatch(fetchSpecificProfileImageError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};

const fetchSpecificBannerImageInProgress = () => {
    return {
        type: SPECIFIC_BANNER_IMAGE_FETCH_IN_PROGRESS,
    };
};

const fetchSpecificBannerImageSuccess = (value) => {
    return {
        type: SPECIFIC_BANNER_IMAGE_FETCH_SUCCESS,
        value,
    };
};

const fetchSpecificBannerImageError = (message) => {
    return {
        type: SPECIFIC_BANNER_IMAGE_FETCH_ERROR,
        message,
    };
};

export const fetchSpecificBannerImage = (id) => (dispatch) => {
    dispatch(fetchSpecificBannerImageInProgress());

    const URL = specificUserBanner(id);
    Axios.get(URL, {
        headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
    })
        .then((res) => {
            const imageUrl = { url: res.data.result };
            dispatch(fetchSpecificBannerImageSuccess(imageUrl));
        })
        .catch((error) => {
            dispatch(fetchSpecificBannerImageError(
                error.response &&
                error.response.data &&
                error.response.data.message
                    ? error.response.data.message
                    : 'Failed!',
            ));
        });
};
