import TimezoneSelect from 'react-timezone-select';
import React from 'react';
import * as PropTypes from 'prop-types';
import './index.css';

const TimezoneSelectField = (props) => {
    const {
        onChange,
        value,
    } = props;

    return (
        <>
            <label className="text-white text-sm mb-4 font-semibold block">Timezone <span>*</span> </label>
            <TimezoneSelect
                className="timezone-select rounded-md bg-custom-dark-2 w-full font-oswald-text font-semibold"
                value={value}
                onChange={onChange}/>
        </>
    );
};

TimezoneSelectField.propTypes = {
    value: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default TimezoneSelectField;
