import { combineReducers } from 'redux';
import {
    ACTIVE_FILTER_SET,
    SIGNUPS_FILTER_SET,
    USERS_LIST_FETCH_ERROR,
    USERS_LIST_FETCH_IN_PROGRESS,
    USERS_LIST_FETCH_SUCCESS,
    SPECIFIC_USER_INFO_ERROR,
    SPECIFIC_USER_INFO_IN_PROGRESS,
    SPECIFIC_USER_INFO_SUCCESS,
    SPECIFIC_PROFILE_IMAGE_FETCH_IN_PROGRESS,
    SPECIFIC_PROFILE_IMAGE_FETCH_SUCCESS,
    SPECIFIC_PROFILE_IMAGE_FETCH_ERROR,
    SPECIFIC_BANNER_IMAGE_FETCH_IN_PROGRESS,
    SPECIFIC_BANNER_IMAGE_FETCH_SUCCESS,
    SPECIFIC_BANNER_IMAGE_FETCH_ERROR,
} from '../constants/users';
import {
    DEFAULT_COUNT,
    DEFAULT_LIMIT,
    DEFAULT_ORDER,
    DEFAULT_SEARCH_KEY,
    DEFAULT_SKIP,
    DEFAULT_SORT_BY,
} from '../constants/url';

const filterOptions = (state = [
    '24 hrs',
    '7 days',
    '30 days',
]) => {
    return state;
};

const activeFilter = (state = '24 hrs', action) => {
    if (action.type === ACTIVE_FILTER_SET) {
        return action.value;
    }

    return state;
};

const specificUserInfo = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case SPECIFIC_USER_INFO_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case SPECIFIC_USER_INFO_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case SPECIFIC_USER_INFO_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const specificProfilePic = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case SPECIFIC_PROFILE_IMAGE_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case SPECIFIC_PROFILE_IMAGE_FETCH_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case SPECIFIC_PROFILE_IMAGE_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const specificBannerPic = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case SPECIFIC_BANNER_IMAGE_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case SPECIFIC_BANNER_IMAGE_FETCH_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case SPECIFIC_BANNER_IMAGE_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const signupsFilter = (state = '24 hrs', action) => {
    if (action.type === SIGNUPS_FILTER_SET) {
        return action.value;
    }

    return state;
};

const list = (state = {
    inProgress: false,
    list: [],
    skip: DEFAULT_SKIP,
    limit: DEFAULT_LIMIT,
    count: DEFAULT_COUNT,
    searchKey: DEFAULT_SEARCH_KEY,
    sortBy: DEFAULT_SORT_BY,
    order: DEFAULT_ORDER,
}, action) => {
    switch (action.type) {
    case USERS_LIST_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
            list: [],
        };
    case USERS_LIST_FETCH_SUCCESS:
        return {
            inProgress: false,
            list: action.value.list,
            count: action.value.count,
            skip: action.skip,
            limit: action.limit,
            searchKey: action.searchKey,
            sortBy: action.sortBy,
            order: action.order,
        };
    case USERS_LIST_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
            count: DEFAULT_COUNT,
        };
    default:
        return state;
    }
};

export default combineReducers({
    filterOptions,
    activeFilter,
    signupsFilter,
    specificUserInfo,
    specificProfilePic,
    specificBannerPic,
    list,
});
