import React from 'react';
import Cards from './Cards';
import './index.css';
import UnstakeRequestTable from './UnstakeRequestTable';
import ExngClaimsTable from './ExngClaimsTable';

const Dashboard = () => {
    return (
        <div className="dashboard content_padding">
            <Cards/>
            <UnstakeRequestTable pagination/>
            <ExngClaimsTable pagination/>
        </div>
    );
};

export default Dashboard;
