import React, {
    useEffect,
    useState,
    useContext,
} from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import {
    // Button,
    Dialog,
    DialogActions,
    DialogContent,
} from '@material-ui/core';
import variables from '../../../utils/variables';
import { addNewDeal, updateDeal } from '../../../actions/deals';
import DotsLoading from '../../../components/DotsLoading';
import Snackbar from '../../../components/Snackbar';
import TextField from '../../../components/TextField';
import TimezoneSelectField from '../../../components/TimezoneSelectField';
import TimePickerField from '../../../components/TimePickerField';
import TierLimitField from '../../../components/TierLimitField';
import DatePickerField from '../../../components/DatePickerField';
import SelectField from '../../../components/SelectField';
import CloseDialog from '../../../components/CloseDialog';
import { SocketContext } from '../../../context/socket';
import Button from '../../../components/Button';
import './index.css';

const DealModal = (props) => {
    const currentDate = new Date();
    const [name, setName] = useState('');
    const [fullName, setFullName] = useState('');
    const [description, setDescription] = useState('');
    const [fee, setFee] = useState('');
    const [gatherLink, setGatherLink] = useState('');
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [addMode, setAddMode] = useState(true);
    const [round, setRound] = useState('');
    const [totalAllocation, setTotalAllocation] = useState(0);
    const [timezone, setTimezone] = useState({});
    const [postDate, setPostDate] = useState(currentDate);
    const [dealTimezone, setDealTimeZone] = useState('');
    const [postTime, setPostTime] = useState('');
    const [postTierLimit, setPostTierLimit] = useState('');
    const [link1, setLink1] = useState('');
    const [link2, setLink2] = useState('');
    const [link3, setLink3] = useState('');

    const {
        dealConfig,
        dealData,
        addNewDeal,
        updateDeal,
        fetchDefault,
        onClose,
        inProgress,
        onSuccess,
        updateInProgress,
        lang,
        open,
        profileDetails: {
            _id,
        },
    } = props;

    const socket = useContext(SocketContext);

    useEffect(() => {
        if (dealData) {
            const {
                name,
                full_name: fullName,
                description,
                fee,
                gather_link: gatherLink,
                round,
                total_allocation: totalAllocation,
                link_1: link1,
                link_2: link2,
                link_3: link3,
            } = dealData;

            setName(name);
            setFullName(fullName);
            setDescription(description);
            setFee(fee);
            setGatherLink(gatherLink);
            setRound(round);
            setTotalAllocation(totalAllocation);
            setLink1(link1);
            setLink2(link2);
            setLink3(link3);
            setAddMode(false);
        }
    }, [dealData]);

    useEffect(() => {
        if (!isEmpty(dealConfig) && addMode) {
            const {
                deal_post_time: dealPostTime,
                deal_post_tier_limit: dealPostTierLimit,
            } = dealConfig;

            setPostTime(dealPostTime);
            setPostTierLimit(dealPostTierLimit);
        }
    }, [dealConfig, addMode]);

    const openSnackbarOnError = (message) => {
        setSnackbarMessage(message);
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const roundList = [
        {
            name: 'Private',
            value: 'Private',
        },
        {
            name: 'Seed',
            value: 'Seed',
        },
        {
            name: 'Strategic',
            value: 'Strategic',
        },
    ];

    const onTimezoneChange = (e) => {
        setTimezone(e);
        setDealTimeZone(e.value);
    };

    const clearForm = () => {
        if (addMode) {
            setName('');
            setFullName('');
            setDescription('');
            setFee('');
            setGatherLink('');
            setRound('');
            setTotalAllocation('');
            setLink1('');
            setLink2('');
            setLink3('');
        }
    };

    const submitForm = () => {
        if (addMode) {
            if (!name) {
                openSnackbarOnError(variables[lang]['name_required']);
                return;
            }

            if (!fullName) {
                openSnackbarOnError(variables[lang]['full_name_required']);
                return;
            }

            if (!description) {
                openSnackbarOnError(variables[lang]['description_required']);
                return;
            }

            if (!fee) {
                openSnackbarOnError(variables[lang]['fee_required']);
                return;
            }

            if (!gatherLink) {
                openSnackbarOnError(variables[lang]['gatherdao_required']);
                return;
            }

            if (!round) {
                openSnackbarOnError(variables[lang]['round_required']);
                return;
            }

            if (!totalAllocation) {
                openSnackbarOnError(variables[lang]['allocation_required']);
                return;
            }

            if (!dealTimezone) {
                openSnackbarOnError(variables[lang]['timezone_required']);
                return;
            }

            if (!moment(postDate).isSameOrAfter(moment(new Date()), 'day')) {
                openSnackbarOnError(variables[lang]['past_date']);
                return;
            }

            const data = {
                name,
                fullName,
                description,
                fee,
                gatherLink,
                round,
                totalAllocation,
                postDate: moment(postDate).format('YYYY-MM-DD'),
                postTimezone: dealTimezone,
                postTime,
                postTierLimit,
            };

            if (link1) {
                data.link1 = link1;
            }

            if (link2) {
                data.link2 = link2;
            }

            if (link3) {
                data.link3 = link3;
            }

            addNewDeal(data, (error, result) => {
                if (result && !inProgress) {
                    const data = {
                        from: _id,
                        fromType: 'admin_user',
                        reference: result,
                        referenceType: 'deal',
                        content: 'New deal created',
                        postTierLimit,
                    };
                    onSuccess(variables[lang]['add_deal_success']);

                    socket.emit('new-notification', data, (error) => {
                        if (error) {
                            openSnackbarOnError(`${error}`);
                        } else {
                            fetchDefault();
                            clearForm();
                            onClose();
                        }
                    });
                } else if (error) {
                    openSnackbarOnError(`${error}`);
                }
            });
        } else {
            const {
                _id,
                name: dealName,
                full_name: dealFullName,
                description: dealDescription,
                fee: dealFee,
                gather_link: dealGatherLink,
                round: dealRound,
                total_allocation: dealTotalAllocation,
                link_1: dealLink1,
                link_2: dealLink2,
                link_3: dealLink3,
            } = dealData;

            const data = {};

            if (dealName !== name) {
                data.name = name;
            }

            if (dealFullName !== fullName) {
                data.fullName = fullName;
            }

            if (dealDescription !== description) {
                data.description = description;
            }

            if (dealFee !== fee) {
                data.fee = fee;
            }

            if (dealGatherLink !== gatherLink) {
                data.gatherLink = gatherLink;
            }

            if (dealRound !== round) {
                data.round = round;
            }

            if (dealTotalAllocation !== totalAllocation) {
                data.totalAllocation = totalAllocation;
            }

            if (dealLink1 !== link1) {
                data.link1 = link1;
            }

            if (dealLink2 !== link2) {
                data.link2 = link2;
            }

            if (dealLink3 !== link3) {
                data.link3 = link3;
            }

            updateDeal(_id, data, (error) => {
                if (!error && !updateInProgress) {
                    onSuccess(variables[lang]['update_deal_success']);
                    fetchDefault();
                    onClose();
                } else if (error) {
                    openSnackbarOnError(`${error}`);
                }
            });
        }
    };

    return (
        <>
            <Snackbar
                message={snackbarMessage}
                open={snackbarOpen}
                severity="error"
                onClose={handleSnackbarClose}
            />
            <Dialog
                aria-describedby="deal-dialog-description"
                aria-labelledby="deal-dialog-title"
                className="dialog deal_dialog"
                maxWidth="lg"
                open={open}
                onClose={onClose}
            >
                <DialogContent className="content relative text-white">
                    <p className="font-oswald-text font-bold text-2xl">
                        {
                            addMode ? (
                                variables[lang]['new_deal_header']
                            ) : (
                                variables[lang]['update_deal_header']
                            )
                        }
                    </p>
                    <CloseDialog className="absolute top-4 md:top-10 right-6 md:right-14" onClose={onClose}/>
                    <form
                        noValidate
                        autoComplete="off"
                        className="form flex flex-col md:flex-row"
                    >
                        <div className="w-full md:w-1/2 px-4">
                            <div className="flex flex-col mb-4 font-oswald-text">
                                <p className="block text-base font-semibold">
                                    <span className="text-white ">
                                        {variables[lang]['new_deal_form_name']}
                                    </span>
                                    {
                                        addMode ? (
                                            <span className="text-white">&nbsp;*</span>
                                        ) : ''
                                    }
                                </p>
                                <TextField
                                    id="new_deal_name"
                                    name="name"
                                    placeholder="Enter deal short name"
                                    value={name}
                                    onChange={setName}
                                />
                            </div>
                            <div className="flex flex-col mb-4 font-oswald-text">
                                <p className="block text-base font-semibold">
                                    <span className="text-white ">
                                        {variables[lang]['new_deal_form_full_name']}
                                    </span>
                                    {
                                        addMode ? (
                                            <span className="text-white">&nbsp;*</span>
                                        ) : ''
                                    }
                                </p>
                                <TextField
                                    id="new_deal_name"
                                    name="name"
                                    placeholder="Enter deal full name"
                                    value={fullName}
                                    onChange={setFullName}
                                />
                            </div>
                            <div className="flex flex-col mb-4 font-oswald-text">
                                <p className="block text-base font-semibold">
                                    <span className="text-white ">
                                        {variables[lang]['new_deal_form_description']}
                                    </span>
                                    {
                                        addMode ? (
                                            <span className="text-white">&nbsp;*</span>
                                        ) : ''
                                    }
                                </p>
                                <TextField
                                    id="new_deal_form_description"
                                    multiline={true}
                                    name="description"
                                    placeholder="Enter your description"
                                    value={description}
                                    onChange={setDescription}
                                />
                            </div>
                            <div className="flex flex-col mb-4 font-oswald-text">
                                <p className="block text-base font-semibold">
                                    <span className="text-white ">
                                        {variables[lang]['new_deal_form_fee']}
                                    </span>
                                    {
                                        addMode ? (
                                            <span className="text-white">&nbsp;*</span>
                                        ) : ''
                                    }
                                </p>
                                <TextField
                                    id="new_deal_fee"
                                    name="fee"
                                    placeholder="Enter your fee"
                                    value={fee}
                                    onChange={setFee}
                                />
                            </div>
                            <div className="flex flex-col mb-4 font-oswald-text">
                                <p className="block text-base font-semibold">
                                    <span className="text-white ">
                                        {variables[lang]['new_deal_form_gather']}
                                    </span>
                                    {
                                        addMode ? (
                                            <span className="text-white">&nbsp;*</span>
                                        ) : ''
                                    }
                                </p>
                                <TextField
                                    id="new_deal_form_gather"
                                    name="gatherLink"
                                    placeholder={variables[lang]['new_deal_form_gather']}
                                    value={gatherLink}
                                    onChange={setGatherLink}
                                />
                            </div>
                            <div className="flex flex-col mb-4 font-oswald-text">
                                <p className="block text-base font-semibold">
                                    <span className="text-white ">
                                        {variables[lang]['new_deal_form_round']}
                                    </span>
                                    {
                                        addMode ? (
                                            <span className="text-white">&nbsp;*</span>
                                        ) : ''
                                    }
                                </p>
                                <SelectField
                                    className="rounded-md bg-custom-dark-2 w-full font-oswald-text font-semibold"
                                    id="new_deal_form_round"
                                    items={roundList}
                                    name="rounds"
                                    placeholder="Select Rounds"
                                    value={round}
                                    onChange={setRound}
                                />
                            </div>
                        </div>
                        <div className="w-full md:w-1/2 px-4">
                            <div className="flex flex-col mb-4 font-oswald-text">
                                <p className="block text-base font-semibold">
                                    <span className="text-white ">
                                        {variables[lang]['new_deal_form_allocation']}
                                    </span>
                                    {
                                        addMode ? (
                                            <span className="text-white">&nbsp;*</span>
                                        ) : ''
                                    }
                                </p>
                                <TextField
                                    id="new_deal_form_allocation"
                                    name="allocation"
                                    placeholder={variables[lang]['new_deal_form_allocation']}
                                    type="number"
                                    value={totalAllocation}
                                    onChange={setTotalAllocation}
                                />
                            </div>
                            <div className="flex flex-col mb-4 font-oswald-text">
                                <p className="text-white  block text-base font-semibold">
                                    {variables[lang]['new_deal_form_link1']}
                                </p>
                                <TextField
                                    id="new_deal_form_link1"
                                    name="link1"
                                    placeholder={variables[lang]['new_deal_form_link_placeholder']}
                                    value={link1}
                                    onChange={setLink1}
                                />
                            </div>
                            <div className="flex flex-col mb-4 font-oswald-text">
                                <p className="text-white  block text-base font-semibold">
                                    {variables[lang]['new_deal_form_link2']}
                                </p>
                                <TextField
                                    id="new_deal_form_link2"
                                    name="link2"
                                    placeholder={variables[lang]['new_deal_form_link_placeholder']}
                                    value={link2}
                                    onChange={setLink2}
                                />
                            </div>
                            <div className="flex flex-col mb-8 font-oswald-text">
                                <p className="text-white  block text-base font-semibold">
                                    {variables[lang]['new_deal_form_link3']}
                                </p>
                                <TextField
                                    id="new_deal_form_link3"
                                    name="link3"
                                    placeholder={variables[lang]['new_deal_form_link_placeholder']}
                                    value={link3}
                                    onChange={setLink3}
                                />
                            </div>
                            {
                                addMode && <>
                                    <div className="flex flex-col mb-8 font-oswald-text">
                                        <DatePickerField value={postDate} onChange={setPostDate}/>
                                    </div>
                                    <div className="flex flex-col mb-6 font-oswald-text">
                                        <TimezoneSelectField value={timezone} onChange={onTimezoneChange}/>
                                    </div>
                                    <div className="flex flex-col md:flex-row justify-between mb-4 font-oswald-text">
                                        <div className="w-full mb-6 md:mb-0 md:w-52 ">
                                            <TimePickerField value={postTime} onChange={setPostTime}/>
                                        </div>
                                        <div className="w-full md:w-52">
                                            <TierLimitField value={postTierLimit} onChange={setPostTierLimit}/>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </form>
                </DialogContent>
                {
                    addMode ? (
                        <DialogActions className="deals_button_div w-4/5 mx-auto flex items-end justify-between">
                            <Button
                                className="min-w-0 mx-2 font-oswald-text cancel_button"
                                color="secondary"
                                size="lg"
                                onClick={() => {
                                    clearForm();
                                    onClose();
                                }}
                            >
                                Cancel
                            </Button>
                            {inProgress
                                ? <DotsLoading/>
                                : <Button
                                    className="mx-2 font-oswald-text"
                                    color="primary"
                                    size="lg"
                                    onClick={() => submitForm()}
                                >
                                    {variables[lang].submit}
                                </Button>
                            }
                        </DialogActions>
                    ) : (
                        <DialogActions className="w-4/5 mx-auto flex items-end justify-between">
                            <Button
                                className="w-1/2 min-w-0 mx-2 font-oswald-text"
                                color="secondary"
                                size="lg"
                                onClick={() => {
                                    clearForm();
                                    onClose();
                                }}
                            >
                                Cancel
                            </Button>
                            {updateInProgress
                                ? <DotsLoading/>
                                : <Button className="mx-2 font-oswald-text" color="primary" size="lg" onClick={submitForm}>
                                    <span>{variables[lang].submit}</span>
                                </Button>
                            }
                        </DialogActions>
                    )
                }
            </Dialog>
        </>
    );
};

DealModal.propTypes = {
    addNewDeal: PropTypes.func.isRequired,
    fetchDefault: PropTypes.func.isRequired,
    inProgress: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    profileDetails: PropTypes.object.isRequired,
    updateDeal: PropTypes.func.isRequired,
    updateInProgress: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    dealConfig: PropTypes.object,
    dealData: PropTypes.object,
};

const stateToProps = (state) => {
    return {
        inProgress: state.deals.addDeal.inProgress,
        lang: state.language,
        message: state.deals.addDeal.message,
        updatedDeal: state.deals.updateDeal.result,
        updateInProgress: state.deals.updateDeal.inProgress,
        profileDetails: state.navigationBar.profileDetails.value,
    };
};

const actionToProps = {
    addNewDeal,
    updateDeal,
};

export default connect(stateToProps, actionToProps)(DealModal);
