import { combineReducers } from 'redux';
import navigationBar from './navigationBar';
import language from './language';
import users from './users';
import signIn from './signIn';
import settings from './settings';
import forgotPassword from './forgotPassword';
import accessToken from './accessToken';
import snackbar from './snackbar';
import unstakeRequests from './unstakeRequests';
import dashboard from './dashboard';
import tipping from './tipping';
import deals from './deals';
import staking from './staking';
import config from './config';
import referrals from './referrals';
import notification from './notification';
import me from './me';
import tasks from './tasks';
import distribution from './distribution';
import forums from './forum';
import messaging from './messaging';

export default combineReducers({
    navigationBar,
    language,
    users,
    signIn,
    settings,
    forgotPassword,
    accessToken,
    snackbar,
    unstakeRequests,
    dashboard,
    tipping,
    deals,
    staking,
    config,
    referrals,
    me,
    notification,
    tasks,
    distribution,
    forums,
    messaging,
});
