import React from 'react';
import * as PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import './index.css';

const TimePickerField = (props) => {
    const {
        value,
        onChange,
    } = props;
    const handleChange = (newValue) => {
        onChange(newValue.target.value);
    };
    return (
        <>
            <label className="text-white text-sm mb-4 font-semibold block">Time</label>
            <div className="timepicker-field rounded-md bg-custom-dark-2 w-full text-white font-oswald-text font-semibold">
                <TextField
                    InputLabelProps={{
                        shrink: true,
                    }}
                    defaultValue={value}
                    id="time"
                    inputProps={{
                        step: 300, // 5 min
                    }}
                    sx={{ width: 150, color: '#fff' }}
                    type="time"
                    onChange={handleChange}
                />
            </div>
        </>
    );
};

TimePickerField.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default TimePickerField;
